<template>
  <div>
    <v-dialog v-model="value" persistent max-width="800px">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title class="bg-gradient-primary-2">
            <span class="white--text">{{
              isEditable ? 'Edit Statement' : 'Create New Statement'
            }}</span>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
              <v-col>
                <ac-combo-box
                  label="User"
                  v-model="userData"
                  :items="companyStaff"
                  class="company-select"
                  :validation-rules="[v => !!v || 'Field is required']"
                  @change="filterBroker(userData.id)"
                />
              </v-col>
              <v-col cols="6">
                <ac-date-picker
                  v-model="joinDate"
                  label="Approval Date"
                  outlined
                  :validation-rules="[v => !!v || 'Field is required']"
                />
              </v-col>
            </v-row>

            <v-row v-if="isEditable">
              <v-col>
                <ac-input
                  v-model="createdUser"
                  label="Created/Edited User"
                  outlined
                  :readonly="true"
                />
              </v-col>
              <v-col cols="6">
                <ac-input
                  v-model="createdDate"
                  label="Date Created/Edited"
                  outlined
                  :readonly="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ac-select
                  label="Broker A/C Number "
                  v-model="brokersNumber"
                  :items="formattedBrokers"
                  class="company-select"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <ac-button
                  title="Upload Approval"
                  :icon="icons.mdiUpload"
                  @click="showUpload = true"
                ></ac-button>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <ac-button title="Cancel" color="error" outlined @click="onClose" />
            <ac-button
              title="Delete"
              color="error"
              :icon="icons.mdiDelete"
              v-if="isEditable"
              @click="confirmRemove"
            />
            <ac-button
              title="Download"
              color="success"
              :icon="icons.mdiDownload"
              :disabled="true"
              v-if="isEditable"
              @click="onDownload"
            />
            <ac-button title="Submit" color="success" :loading="loading" @click="onSubmit" />
            <upload-modal v-model="showUpload" title="approval" />
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <ac-confirmation-dialog
      v-model="showConfirmationDialog"
      description="Are you sure you want to remove this Statement?"
      @onCancel="cancelCompanyRemoval"
      @onOK="onRemove"
    />
  </div>
</template>

<script>
import { emailValidator, required } from '@core/utils/validation'
import { mdiUpload, mdiDownload, mdiDelete } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'AddStatementsModal',

  components: {
    AcInput: () => import('@/components/AcInput'),
    AcButton: () => import('@/components/AcButton'),
    AcSelect: () => import('@/components/AcSelect'),
    AcComboBox: () => import('@/components/AcComboBox.vue'),
    AcDatePicker: () => import('@/components/AcDatePicker'),
    AcIconBtn: () => import('@/components/AcIconBtn'),
    UploadModal: () => import('@/components/modals/UploadModal'),
    AcInput: () => import('@/components/AcInput'),
    AcConfirmationDialog: () => import('@/components/AcConfirmationDialog'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    companyStaff: {
      type: Array,
    },
    editItem: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
    },
  },
  data() {
    return {
      userData: null,
      joinDate: null,
      brokersNumber: null,
      brokers: [],
      formattedBrokers: [],
      loading: false,
      valid: false,
      showUpload: false,
      createdUser: '',
      createdDate: '',
      showConfirmationDialog: false,
      recordToDelete: null,
      icons: {
        mdiUpload,
        mdiDownload,
        mdiDelete,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },

  computed: {
    ...mapGetters('app', ['selectedCompany']),
    ...mapGetters('paDealing', ['companyStaffAccounts', 'getStatements']),
  },
  methods: {
    ...mapActions('paDealing', ['createStatement', 'updateStatement', 'removeStatement']),

    filterBroker(id) {
      this.formattedBrokers = []
      this.brokers = this.companyStaffAccounts.filter(account => account.userID == id)
      this.brokers.forEach(element => {
        this.formattedBrokers.push({ name: element.accountNumber, id: element.id })
      })
    },
    async onSubmit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.loading = true

        if (this.isEditable) {
          const { createdUser, id } = this.editItem.monthRecord

          const payload = {
            _id: id,
            name: this.userData,
            brokerNumber: this.brokersNumber,
            broker: this.editItem.brokerId,
            date: this.joinDate,
            user: createdUser,
            createsUser: this.createdUser,
            createdData: this.createdDate,
          }

          await this.updateStatement(payload)

          this.onClose(true)
        } else {
          const formatDate = moment(this.joinDate).format('DD MMM YYYY')

          const payload = {
            user: this.userData.id,
            broker: this.brokersNumber,
            date: formatDate,
          }

          const { success, message } = await this.createStatement(payload)

          const notificationType = success ? 'success' : 'error'
          const position = 'bottom'
          this.AcNotify({
            type: notificationType,
            message,
            position,
          })
        }

        this.onClose(true)
        this.loading = false
      }
    },
    cancelCompanyRemoval() {
      this.showConfirmationDialog = false
      this.recordToDelete = null
    },
    confirmRemove() {
      this.showConfirmationDialog = true
      this.recordToDelete = this.editItem.monthRecord.id
    },
    async onRemove() {
      this.showConfirmationDialog = false

      const { success, message } = await this.removeStatement(this.recordToDelete)

      const notificationType = success ? 'success' : 'error'
      const position = 'bottom'

      this.AcNotify({
        type: notificationType,
        message,
        position,
      })
      this.onClose(true)
    },
    async onDownload() {
      let { link } = this.editItem.monthRecord

      link = 'https://ibb.co/0YxGJW9'
      window.open(link, '_blank')
    },
    onClose(refetch) {
      this.userData = null
      this.joinDate = null
      if (refetch) {
        const { selectedCompany } = this
        this.$emit('onClose', { selectedCompany })
      }

      this.reset()
      this.$emit('input', false)
    },
    getCreatedUser(createdUser) {
      return this.companyStaffAccounts.find(account => account.createdId === createdUser)
    },
    getBrokers(userId) {
      return this.companyStaffAccounts.filter(account => account.userID === userId)
    },
    reset() {
      this.licenses = []
      this.$refs.form.reset()
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (!val || !this.isEditable) return

        const { createdDate, date, createdUser } = this.editItem.monthRecord
        const { userId, brokerId, userName } = this.editItem
        const user = this.getCreatedUser(createdUser)

        this.brokers = this.getBrokers(userId)
        this.formattedBrokers = this.brokers.map(element => ({
          name: element.accountNumber,
          id: element.id,
        }))
        this.userData = userName
        this.brokersNumber = brokerId
        this.joinDate = date
        this.createdDate = moment(createdDate).format('DD MMM YYYY')
        this.createdUser = user?.createdBy || 'Usman Muhammad'
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
